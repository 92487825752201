<template>
  <div v-if="siteStatus" :class="['wrapper blurred', backgroundImage]">
    <router-view class="animated fadeIn" />
    <Cookies v-if="!cookiesEnabled" />
    <Regulation
      v-if="isGlobal && !regulationAccepted && isEurope == true"
      @acceptRegulation="acceptRegulation"
    />
  </div>
  <Maintenance v-if="!siteStatus" />
</template>
<script>
import { getters, mutations } from "../store";
import Cookies from "../components/Cookies.vue";
import Regulation from "../components/Regulation";
import Maintenance from "../components/Maintenance";
import { checkCountry } from "../helpers/countries";
export default {
  data() {
    return {
      siteStatus: true,
      isEurope: false,
      isProduction: process.env.NODE_ENV !== "development",
    };
  },
  beforeMount() {
    if (this.isProduction) {
      this.checkSiteStatus();
    }
  },
  mounted() {
    if (this.siteStatus) {
      this.getCountries();
      this.changeLanguage();
    }
  },
  watch: {
    $route() {
      this.changeLanguage();
    },
  },
  components: {
    Regulation,
    Maintenance,
    Cookies,
  },
  computed: {
    license() {
      return getters.license();
    },
    regulationAccepted() {
      return getters.regulationAccepted();
    },
    isGlobal() {
      return this.license === "global";
    },
    backgroundImage() {
      return this.isGlobal ? "global" : "";
    },
    countries() {
      return getters.countries();
    },
    host() {
      return window.location.host;
    },
    cookiesEnabled() {
      return getters.cookiesEnabled();
    },
    selectedLanguage() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    changeLanguage() {
      document.title = `TrioMarkets | ${this.$route.name}`;
      this.$root.$i18n.locale = this.$route.params.lang;
      mutations.changeLanguage(this.$root.$i18n.locale);
      if (this.$root.$i18n.locale === "arb") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
    },
    checkSiteStatus() {
      this.$axios({
        method: "GET",
        url: `/hub/maintenance/checkSite/${this.host}`,
      })
        .then(({ data }) => {
          if (data.success) {
            this.siteStatus = data.status;
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getCountries() {
      this.$axios({
        method: "GET",
        url: `/crm/countries`,
      })
        .then(({ data }) => {
          console.log(data)
          if (data.success) {
            let countries = data.countriesList.filter(({ iso_alpha2_code }) => {
              if (this.isGlobal) {
                if (iso_alpha2_code === "GB" || iso_alpha2_code === "CH") {
                  let changeISO = iso_alpha2_code;
                  let findCountry = data.countriesList.find(
                    ({ iso_alpha2_code }) => {
                      return changeISO === iso_alpha2_code;
                    }
                  );
                  if (findCountry) {
                    findCountry.currency = "USD";
                  }
                }
                return (
                  iso_alpha2_code !== "KSV" &&
                  iso_alpha2_code !== "TP" &&
                  iso_alpha2_code !== "RO"
                );
              }
              return iso_alpha2_code !== "KSV" && iso_alpha2_code !== "TP";
            });
            mutations.setCountries(countries);
            this.getCountry();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getCountry() {
      this.$axios.get("https://api.ipify.org?format=json").then(({ data }) => {
        this.$axios.get(`ip/findIP/${data.ip}`).then(({ data }) => {
          let clientCountry = data.country.iso_code.toUpperCase();
          let { country_id, tel_country_code, currency } = this.countries.find(
            ({ iso_alpha2_code }) => {
              return iso_alpha2_code === clientCountry;
            }
          );
          let countryFound = checkCountry(clientCountry, "en");
          if (countryFound) {
            this.$router.replace({
              name: this.$route.name,
              params: { lang: countryFound },
              query: this.$route.query,
            });
          }
          mutations.setCountry({
            country_id: country_id.toString(),
            phoneExt: tel_country_code,
            currency: currency,
          });
          mutations.setUser({
            ...getters.user(),
            ip: this.isProduction ? data.ip : "81.4.163.54",
          });
          this.checkEurope(clientCountry);
        });
      });
    },
    checkEurope(ipCode) {
      const euList = [
        { country: "Austria", code: "AT" },
        { country: "Belgium", code: "BE" },
        { country: "Bulgaria", code: "BG" },
        { country: "Croatia", code: "HR" },
        { country: "Cyprus", code: "CY" },
        { country: "Czech Republic", code: "CZ" },
        { country: "Denmark", code: "DK" },
        { country: "Estonia", code: "EE" },
        { country: "Finland", code: "FI" },
        { country: "France", code: "FR" },
        { country: "Germany", code: "DE" },
        { country: "Greece", code: "GR" },
        { country: "Hungary", code: "HU" },
        { country: "Ireland", code: "IE" },
        { country: "Italy", code: "IT" },
        { country: "Latvia", code: "LV" },
        { country: "Lithuania", code: "LT" },
        { country: "Luxembourg", code: "LU" },
        { country: "Malta", code: "MT" },
        { country: "Netherlands", code: "NL" },
        { country: "Poland", code: "PL" },
        { country: "Portugal", code: "PT" },
        { country: "Romania", code: "RO" },
        { country: "Slovakia", code: "SK" },
        { country: "Slovenia", code: "SI" },
        { country: "Spain", code: "ES" },
        { country: "Sweden", code: "SE" },
      ];
      this.isEurope = euList.some(({ code }) => {
        return code === ipCode;
      });
    },
  },
};
</script>
