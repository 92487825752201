<template>
  <div id="registerStep" class="px-3">
    <h3 class="fs-3 text-center fw-normal mt-sm-0 mt-5">
      {{ $t("register.steps.step1.title") }}
    </h3>
    <h4 class="lh-1 text-center fs-6 fw-normal d-sm-block d-none">
      {{ $t("register.steps.step1.subTitle") }}
    </h4>
    <Form @submit="registerClient" :validation-schema="schema"
      class="reg-form d-flex flex-column justify-content-around align-items-center w-100 px-sm-4 py-md-4 py-sm-3">
      <div class="radios w-100 d-flex justify-content-around align-items-center">
        <div id="individual" @click="corporate = false" :class="`radio ${corporate == false ? ' active' : ''} ${checkArb ? 'rtl' : ''
        }`">
          <a class="p-2" href="#">{{
        $t("register.steps.step1.accountType.individual")
      }}</a>
        </div>
        <div id="company" @click="corporate = true" :class="`radio ${corporate == true ? 'active' : ''} ${checkArb ? 'rtl' : ''
        }`">
          <a href="#">{{ $t("register.steps.step1.accountType.company") }}</a>
        </div>
      </div>
      <select class="form-select d-sm-none" @change="changeCorporate">
        <option value="0" disabled="disabled" selected="selected">
          Account Type
        </option>
        <option value="1">Individual</option>
        <option value="2">Company</option>
      </select>
      <Field name="company" @input="updateField('company', accountData.company)" v-model="accountData.company"
        v-slot="{ field, meta }" v-if="corporate">
        <input v-bind="field" :class="{
        'form-control': true,
        'is-invalid': !meta.valid && meta.touched,
      }" :placeholder="$t('register.steps.step1.accountType.company')" />
      </Field>
      <ErrorMessage v-if="corporate" name="company" class="error" />
      <Field name="fname" v-model="accountData.fname" @input="updateField('fname', accountData.fname)"
        v-slot="{ field, meta }">
        <input v-bind="field" :class="{
        'form-control': true,
        'is-invalid': !meta.valid && meta.touched,
      }" :placeholder="$t('register.steps.step1.accountType.fname')" />
      </Field>
      <ErrorMessage name="fname" class="error" />
      <Field name="lname" @input="updateField('lname', accountData.lname)" v-model="accountData.lname"
        v-slot="{ field, meta }">
        <input v-bind="field" :class="{
        'form-control': true,
        'is-invalid': !meta.valid && meta.touched,
      }" :placeholder="$t('register.steps.step1.accountType.lname')" />
      </Field>
      <ErrorMessage name="lname" class="error" />
      <Field name="email" @input="updateField('email', accountData.email)" v-model="accountData.email"
        v-slot="{ field, meta }">
        <input v-bind="field" :class="{
        'form-control': true,
        'is-invalid': !meta.valid && meta.touched,
      }" :placeholder="$t('register.steps.step1.accountType.email')" />
      </Field>
      <ErrorMessage name="email" class="error" />
      <div class="inline-flex w-100 d-flex">
        <Field name="password" v-model="accountData.password" v-slot="{ field, meta }">
          <input type="password" v-bind="field" :class="[
        'form-control',
        !meta.valid && meta.touched ? 'is-invalid' : '',
        checkArb ? 'ms-2' : 'me-2',
      ]" :placeholder="$t('register.steps.step1.accountType.pass')" />
        </Field>
        <Field name="repeatPassword" v-model="accountData.repeatPassword" v-slot="{ field, meta }">
          <input type="password" v-bind="field" :class="{
        'form-control': true,
        'is-invalid': !meta.valid && meta.touched,
      }" :placeholder="$t('register.steps.step1.accountType.rpass')" />
        </Field>
      </div>
      <ErrorMessage name="password" class="error" />
      <ErrorMessage name="repeatPassword" class="error" />
      <div id="passwordHelp" class="form-text">
        {{ $t("register.steps.step1.accountType.passwordHelp") }} ! @ $ % _ # (
        ).
      </div>
      <Field name="country" @input="updateField('country_id', accountData.country_id)" v-model="accountData.country_id"
        v-slot="{ field, meta }">
        <select :class="[
        'form-select',
        !meta.valid && meta.touched ? 'is-invalid' : '',
        checkArb ? 'form-inputs-rtl country-select' : '',
      ]" v-bind="field" @change="updatePhone(accountData.country_id)">
          <option value="0" disabled="disabled" selected="selected">
            {{ $t("register.steps.step1.accountType.country") }}
          </option>
          <option v-for="country in countries" :value="country.country_id" :key="country.country_id">
            {{ country.name }}
          </option>
        </select>
      </Field>
      <ErrorMessage name="country" class="error" />
      <div class="phones-inline w-100 d-flex">
        <Field name="tel_country_code" @input="updateField('tel_country_code', accountData.tel_country_code)"
          v-model="accountData.tel_country_code" v-slot="{ field, meta }" placeholder="Ext">
          <select id="tel_country_code" :class="[
        'form-select ',
        !meta.valid && meta.touched ? 'is-invalid' : '',
        checkArb ? 'ms-2 form-inputs-rtl' : 'me-2',
      ]" v-bind="field">
            <option value="0" disabled="disabled" selected="selected">
              {{ $t("register.steps.step1.accountType.phoneExt") }}
            </option>
            <option v-for="country in countries" :value="`+${country.tel_country_code}`"
              :key="country.tel_country_code">
              {{
        accountData.tel_country_code
          ? `${country.iso_alpha2_code} +${country.tel_country_code}`
          : `${country.name} +${country.tel_country_code}`
      }}
            </option>
          </select>
        </Field>
        <Field name="phone" type="number" @input="updateField('tel_number', accountData.tel_number)"
          v-model="accountData.tel_number" v-slot="{ field, meta }">
          <input v-bind="field" :class="{
        'form-control': true,
        'is-invalid': !meta.valid && meta.touched,
      }" :placeholder="$t('register.steps.step1.accountType.phone')" />
        </Field>
      </div>
      <ErrorMessage name="tel_country_code" class="error" />
      <ErrorMessage name="phone" class="error" />
      <div class="eula w-100 pt-3 d-flex justify-content-around">
        <Field name="eula" type="checkbox" class="chk me-2 mt-1 pointer" v-model="eula"
          @input="updateField('eula', accountData.eula)" :value="true" :unchecked-value="false" />

        <small class="text-justify" v-html="$t('register.steps.step1.eula', {
        license: license,
        clientAgreementUrl: clientAgreementUrl,
        privacyPolicyUrl: privacyPolicyUrl,
        portfolioManagementUrl: portfolioManagementUrl,
        riskDisclosureUrl: riskDisclosureUrl,
        kidsUrl: kidsUrl,
      })
        "></small>
      </div>
      <ErrorMessage name="eula" class="error" />
      <div class="buttonset w-100 text-center">
        <div v-if="response" class="alert alert-danger w-100 text-center mt-4 mb-0" role="alert">
          {{ response }}
        </div>
        <button :disabled="submitted" class="btn btn-gradient-purple w-50">
          <span v-show="!submitted">{{
        $t("register.steps.step1.registerBtn")
      }}</span>
          <loader :isLoading="submitted" />
        </button>
      </div>
      <div class="text-center">
        <p class="mb-0">
          {{ $t("register.steps.step1.alreadyRegistered") }}
          <router-link :to="`/${$route.params.lang}/login`" class="text-purple">{{ $t("register.steps.step1.loginBtn")
            }}</router-link>
        </p>
      </div>
    </Form>
  </div>
</template>
<script>
import loader from "../loader";
import { debounce } from 'lodash';
import { getters, mutations } from "../../store";
import { object, string, boolean, ref } from "yup";
import { Field, Form, ErrorMessage } from "vee-validate";
export default {
  data() {
    return {
      accountData: {
        user_data_id: null,
        company: "",
        fname: "",
        lname: "",
        email: "",
        password: "",
        repeatPassword: "",
        country_id: 0,
        currency: "",
        tel_country_code: "",
        tel_number: "",
        httpref: window.location.origin,
        is_ib: "",
        sidc: "",
        sidi: "",
        suid: "",
        license: "",
        language: "en",
        source: "",
        remote_host: "",
        remote_addr: "",
        agreement_id_list: 0,
      },
      eula: false,
      response: "",
      corporate: false,
      submitted: false,
      backendApiUrl: "https://testform.triomarkets.com/backend/api/user_data",
      updateQueue: [],
      isUpdating: false,
    };
  },
  components: {
    loader,
    Field,
    Form,
    ErrorMessage,
  },
  computed: {
    schema() {
      return object({
        corporate: boolean().default(this.corporate),
        company: string().when("corporate", {
          is: true,
          then: (schema) =>
            schema
              .matches(/^[A-Za-z ]+$/i, {
                message: this.$t(
                  "register.steps.step1.validation.companyLatin"
                ),
                excludeEmptyString: true,
              })
              .required(
                this.$t("register.steps.step1.validation.companyRequired")
              ),
        }),
        fname: string()
          .matches(/^[A-Za-z ]+$/i, {
            message: this.$t("register.steps.step1.validation.fnameLatin"),
            excludeEmptyString: true,
          })
          .required(this.$t("register.steps.step1.validation.fnameRequired")),
        lname: string()
          .matches(/^[A-Za-z ]+$/i, {
            message: this.$t("register.steps.step1.validation.lnameLatin"),
            excludeEmptyString: true,
          })
          .required(this.$t("register.steps.step1.validation.lnameRequired")),
        email: string()
          .required(this.$t("register.steps.step1.validation.emailRequired"))
          .email(this.$t("register.steps.step1.validation.emailValid")),
        password: string()
          .min(8, this.$t("register.steps.step1.validation.passwordLength"))
          .matches(
            /* eslint-disable */
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z >>!#$ %&? "<<])[ a-zA-Z0-9 >>!#$ %&?<< ]/,
            this.$t("register.steps.step1.validation.passwordChar")
          )
          .required(
            this.$t("register.steps.step1.validation.passwordRequired")
          ),
        repeatPassword: string().oneOf(
          [ref("password"), null],
          this.$t("register.steps.step1.validation.passwordNotMatch")
        ),
        country: string()
          .required(this.$t("register.steps.step1.validation.countryRequired"))
          .notOneOf(
            ["0", 0],
            this.$t("register.steps.step1.validation.countryRequired")
          ),
        tel_country_code: string()
          .required(this.$t("register.steps.step1.validation.phoneExtRequired"))
          .notOneOf(
            ["0", 0],
            this.$t("register.steps.step1.validation.phoneExtRequired")
          ),
        phone: string().required(
          this.$t("register.steps.step1.validation.phoneRequired")
        ),
        eula: string().notOneOf(
          [false, "false", undefined],
          this.$t("register.steps.step1.validation.eulaRequired")
        ),
      });
    },
    countries() {
      return getters.countries();
    },
    license() {
      return getters.license();
    },
    clientAgreementUrl() {
      return getters.clientAgreementUrl();
    },
    privacyPolicyUrl() {
      return getters.privacyPolicyUrl();
    },
    portfolioManagementUrl() {
      return getters.portfolioManagementUrl();
    },
    riskDisclosureUrl() {
      return getters.riskDisclosureUrl();
    },
    kidsUrl() {
      return getters.kidsUrl();
    },
    is_ib() {
      return this.$route.query.triopartners ?? "";
    },
    sidc() {
      return this.$route.query.sidc ?? "";
    },
    sidi() {
      return this.$route.query.sidi ?? "";
    },
    suid() {
      return this.$route.query.suid ?? "";
    },
    source() {
      return this.$route.query.source ?? "";
    },
    selectedCountry() {
      return getters.selectedCountry();
    },
    checkArb() {
      return getters.checkArb();
    },
    user() {
      return getters.user();
    },
  },
  watch: {
    is_ib: {
      handler(is_ib = "") {
        this.accountData.is_ib = is_ib;
      },
      immediate: true,
    },
    sidc: {
      handler(sidc = "") {
        this.accountData.sidc = sidc;
      },
      immediate: true,
    },
    sidi: {
      handler(sidi = "") {
        this.accountData.sidi = sidi;
      },
      immediate: true,
    },
    suid: {
      handler(suid = "") {
        this.accountData.suid = suid;
      },
      immediate: true,
    },
    license: {
      handler(license) {
        this.accountData.license = license;
      },
      immediate: true,
    },
    source: {
      handler(source = "") {
        this.accountData.source = source;
      },
    },
    eula: {
      handler(eula) {
        this.accountData.agreement_id_list = +eula;
      },
    },
    selectedCountry: {
      handler({ country_id, phoneExt, currency }) {
        this.accountData.tel_country_code = `+${phoneExt}`;
        this.accountData.country_id = country_id;
        this.accountData.currency = currency;
      },
      immediate: true,
    },
    user: {
      handler({ ip }) {
        this.accountData = {
          ...this.accountData,
          remote_addr: ip,
          remote_host: ip,
        };
      },
      immediate: true,
    },
    $route() {
      this.accountData.httpref = window.location.origin;
    },
  },
  methods: {
    registerClient: debounce(async function () {
      this.submitted = true;
      await this.$axios({
        method: "POST",
        url: "/crm/register",
        data: this.accountData,
      })
        .then(({ data }) => {
          this.submitted = false;
          if (data.success) {
            this.updateField('registered', true);
            mutations.setUser({
              userID: data.userID,
              email: this.accountData.email,
              name: this.accountData.fname,
            });
            mutations.nextStep(2);
          } else {
            this.response = data.message;
            this.updateField('registered', true);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }, 300),

    updateField(field, value) {
      this.updateQueue.push({ field, value });
      this.processQueue();
    },

    async processQueue() {
      if (this.isUpdating || this.updateQueue.length === 0) return;
      this.isUpdating = true;

      const { field, value } = this.updateQueue.shift();
      try {
        const payload = {
          field,
          value,
          id: this.accountData.user_data_id, // Include the user_data_id in the payload
        };

        if (field === 'country_id') {
          const country = this.countries.find(country => country.country_id === value);
          payload.field = 'country';
          payload.value = country ? country.name : value;
        }

        if (field === 'eula') {
          payload.value = this.eula;
        }

        const response = await fetch(`${this.backendApiUrl}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (!this.accountData.user_data_id) {
          this.accountData.user_data_id = data.id; // Save the ID of the newly created UserData
        }

        if (field === 'tel_number') {
          const telCodePayload = {
            field: 'tel_country_code',
            value: this.accountData.tel_country_code,
            id: this.accountData.user_data_id,
          };

          const telCodeResponse = await fetch(`${this.backendApiUrl}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(telCodePayload),
          });
          await telCodeResponse.json();

          const countryChosen = this.countries.find(country => country.country_id.toString() === this.accountData.country_id.toString());
          const countryPayload = {
            field: 'country',
            value: countryChosen.name,
            id: this.accountData.user_data_id,
          };

          const countryResponse = await fetch(`${this.backendApiUrl}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(countryPayload),
          });

          await countryResponse.json();
        }

        console.log(data.message);
      } catch (error) {
        console.error('Error updating field:', error);
      }

      this.isUpdating = false;
      if (this.updateQueue.length > 0) {
        this.processQueue();
      }
    },

    updatePhone(selectedCountryID) {
      const { tel_country_code, currency } = this.countries.find(
        ({ country_id }) => {
          return parseInt(country_id) === parseInt(selectedCountryID);
        }
      );
      this.accountData.country_id = selectedCountryID.toString();
      this.accountData.tel_country_code = `+${tel_country_code}`;
      this.accountData.currency = currency;
    },

    changeCorporate({ target }) {
      this.corporate = target.value == 2;
    },
  },
};
</script>
<style lang="scss">
.form-control.is-invalid .form-inputs-rtl,
.was-validated .form-control:invalid .form-inputs-rtl {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}

.form-inputs-rtl {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}

.form-control.is-invalid {
  padding-right: 2rem !important;
}

#passwordHelp {
  font-size: 0.7em;
}
</style>
